import React, { Component } from 'react';
import TechTermItem from '../components/TechTermItem';
import PropTypes from 'prop-types';

class TechTerms extends Component {
  
  render () {
    console.log(this.props.termsList)

    return this.props.termsList.map((it) => (
      <TechTermItem key={it.TermId} title={it.title} description={it.description} />
    ));
  }
}

//Using PropTypes to add Validation on props
// TechTerms.propTypes = {
//   termsList: PropTypes.array.isRequired
// }

export default TechTerms;
