import React, { Component } from 'react';
import TechTerms from './components/TechTerms';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import About from './components/About';

import './App.css';
import axios from 'axios';
import PrivacyPolicy from './components/PrivacyPolicy';

class App extends Component {
  state = {
    termsArray: [
      // {
      //   id: 1,
      //   title: 'MVC',
      //   description: 'Model View Controller. Design patterm for application development.'
      // }, {
      //   id: 2,
      //   title: 'JAVA',
      //   description: 'Programming language for development of different programs for Web, Mobile or Desktop.'
      // }
    ]
  }
/*
  {
    "TermId": "4",
    "title": "",
    "description": ""
  }
*/
  componentDidMount() {
    axios.get("https://api.techvocab.com/dev/terms", {
        headers: {
            'x-api-key': 'techtermtechtermtechterm'
        }
      })
    .then(res =>  {
        console.log(res.data);
        this.setState({termsArray:res.data.body});
      }
    );
    
  }

  render () {

    const mainContentStyle = {
      flex: '1 0 auto'
    
    }
      
      
    return (
      <BrowserRouter>
      <div className="App" >
        <NavBar />
        <div style={mainContentStyle}>
          <Switch>
            <Route exact path="/" render={props => (
              <React.Fragment>
              <div className="main-container" >
                <div className="items">
                  <TechTerms termsList={this.state.termsArray} />
                </div>
              </div>
              </React.Fragment>
            )} /> 
            <Route exact path="/about-us"  component={About}  />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          </Switch>
          </div>
        <Footer />
      </div>
      </BrowserRouter>

    )
  }
}

export default App;
