import React, { Component } from 'react';
import AddTechTerm from '../components/AddTechTerm';
import { Link } from 'react-router-dom';

class NavBar extends Component {
    render() {
      const linkStyle = {
        textDecoration: 'none',
        color: 'blue',
        marginLeft: '50px'
      }

        return (
        <header  style={{margin: '50px'}}>
            <div className="main-header" >
                <div className="logo-container">
                </div>
              <h1 className=""><b><Link to="/" style={{textDecoration:'none', color:'#000'}}>Tech Vocab</Link></b></h1>
              <h6>Everything about Technology and Solutions</h6>
            </div>
            <div>
              <div>
                <Link to="/about-us" style={linkStyle}>About Us</Link>
              </div>
              <div>
                <Link to="/privacy-policy" style={linkStyle}>Privacy Policy</Link>
              </div>
            </div>
            {/* <AddTechTerm /> */}
          </header>
        );
    }
}

export default NavBar;