import React, { Component } from 'react'

class AddTechTerm extends Component {

    state = {
        title: '',
        description: ''
    }

    updateDetail = (e) => {
        this.setState({[e.target.name]:e.target.value});
    }
    
    render() {
        return (
            <form style={{ display: 'flex' }}>
                <input type="text" name="title" placeholder="Add Tech Term Title..." style={{ flex: '5' }}
                 value={this.state.title} onChange={this.updateDetail}/>
                <textarea type="text" name="description" placeholder="Add Tech Term Description..."  
                style={{ flex: '5', padding: '5px' }} value={this.state.description} onChange={this.updateDetail} />
                <input type="submit" name="submit" className="btn" style={{ flex:'1' }}  />
            </form>
        )
    }
}

export default AddTechTerm;
