import React, { Component } from 'react'

class TechTermItem extends Component {
    getStyle = () => {
        return {
            backgroundColor: '#f4f4f4'
        }
    }

    //allow access to `this` in method
    itemClick = (e) => {
        console.log(this.props);
    }
    render() {
        return (
            <div className="item-container" style={this.getStyle()}>
                <div className="tech-description">
                <p onClick={this.itemClick} ><strong>{this.props.title}</strong> <span dangerouslySetInnerHTML={{__html: this.props.description}} /></p> 
                </div>
            </div>
        )
    }
}

export default TechTermItem
