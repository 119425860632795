import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Footer extends Component {
    render() {
        const linkStyle = {
            textDecoration: 'none',
            color: '#000'
        }

        const footerStyle = {
            flexShrink: 0,
            marginTop: '50px',
            borderTop: '1px solid black'
        }


        return (
            <footer style={footerStyle}>
                <div style={{ paddingTop: '50px'}}>
                    <div>
                        <Link to="/about-us" style={linkStyle}>About Us</Link>
                    </div>
                    <div>
                        <Link to="/privacy-policy" style={linkStyle}>Privacy Policy</Link>
                    </div>
                </div>
                <p>Copyright @TechVocab 2022</p>

            </footer>
        )
    }
}

export default Footer
